import * as React from 'react';
import Button from '@mui/material/Button';
import { Modal } from '@mui/material';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate, TextField } from 'mui-rff';
import styles from './Authenticate.module.scss';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';  // Import FileSaver.js to trigger PDF download

const schema = Yup.object().shape({
  dob: Yup.string().required().matches(
    /^(0[1-9]|[12][0-9]|3[01])[-./](0[1-9]|1[012])[-./]\d{4}$|^\d{8}$/,
    'Please use DD-MM-YYYY, DD.MM.YYYY, DD/MM/YYYY, or DDMMYYYY'
  ),
});

const validate = makeValidate(schema);

function Authenticate({ openAuthenticate, setOpenAuthenticate, consentId }) {
  const [versionChecked, setVersionChecked] = React.useState(false);
  const [isLatest, setIsLatest] = React.useState(null);

  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation(); // Get current location

  const { isLoading: isCheckingVersion, error: versionError } = useQuery(
    ['checkIfLatestVersion', consentId],
    () => axios.get(`/patient/consent_bundle/${consentId}/check_if_latest_version`).then(res => res.data),
    {
      enabled: !versionChecked && !!consentId,
      onSuccess: (data) => {
        console.log(data);
        if (data === true) {
          return;
        } else {
          navigate(`/${data}`);
        }
      }
    }
  );

  const authenticate = useMutation(
    (dob) => {
      return axios.get(`/patient/authenticate?consent_bundle_id=${consentId}&dob=${dob}`);
    },
    {
      onSuccess: (data) => {
        if (data.data) {
          setOpenAuthenticate(false);
        }
      },
      onError: (error) => {
        console.error("Authentication failed:", error);
      }
    }
  );

  const downloadPdf = (dob) => {
    console.log(dob);
    
    axios
      .get(`/patient/authenticate?consent_bundle_id=${consentId}&dob=${dob}`)
      .then((data) => {
        if (data.data) {
          navigate(`/${consentId}/download/pdf`);
         
        } else {
          console.error("Authentication failed: Invalid DOB.");
        }
      })
      .catch((error) => {
        console.error("Authentication failed:", error);
      });
  };

  const sendHelpData = (data) => {
    authenticate.mutate(data.dob);
  };

  const handleKeyPress = (e) => {
    const allowedCharacters = /[0-9\-\/.]/;
    const key = e.key;
    if (key === 'Enter') {
      e.preventDefault();
      sendHelpData({ dob: e.target.value });
    } else if (!allowedCharacters.test(key)) {
      e.preventDefault();
    }
  };

  const isDownloadPage = location.pathname.includes('/download'); 

  return (
    <Modal open={openAuthenticate}>
      <div className="modal">
        <h4>Please input your DOB to access the information compiled by your dentist</h4>
        <Form
          onSubmit={sendHelpData}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form className={styles.headerForm} onSubmit={handleSubmit} noValidate>
              <p><i>Please add your birthday in this format (DDMMYYYY)</i></p>
              <TextField
                label="Birthday"
                name="dob"
                required={true}
                onKeyPress={handleKeyPress}
              />
              {!isDownloadPage ? (
                <Button className={styles.submitButton} type="submit" variant="contained" disabled={authenticate.isLoading}>
                  Authenticate
                </Button>
              ) : (
                <Button
                  className={styles.submitButton}
                  type="button"
                  variant="contained"
                  disabled={authenticate.isLoading}
                  onClick={() => downloadPdf(values.dob)} 
                >
                  Authenticate to download
                </Button>
              )}
            </form>
          )}
        />
        <br />
        <a href="/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
    </Modal>
  );
}

export default Authenticate;
