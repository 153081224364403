import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Button from "@mui/material/Button";
import { Modal, Box, Typography, Grid, Divider } from "@mui/material";
import { Form } from "react-final-form";
import { Radios, Checkboxes, TextField } from 'mui-rff';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { saveAs } from "file-saver";
import styles from './Thankyou.module.scss';

export default function Download() {
  const { consentId } = useParams();
  const navigate = useNavigate();
  const [haveFeedback, setHaveFeedback] = React.useState(false);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      // navigate(`/${consentId}/download`);
    }
  }, [consentId, navigate]);

  const downloadPdf = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentId}/generate_patient_pdf`,
        { responseType: "blob" }
      )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        saveAs(file, "consent_bundle.pdf");
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  const feedback = useMutation(
    (data) => {
      return axios.post(`/patient/feedback?consent_bundle_id=${consentId}`, data);
    },
    {
      onSuccess: () => {
        setHaveFeedback(true);
      }
    }
  );

  const sendFeedback = (data) => {
    feedback.mutate(data);
  };

  return (
    <div className={styles.thankyou}>
      <Box
        sx={{
          maxWidth: 800,
          margin: "auto",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h3" align="center" gutterBottom>
          Thank You
        </Typography>

        {/* Download PDF Button */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => downloadPdf()}
              fullWidth
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" align="center" paragraph>
          Share your feedback in the form below to help us improve
        </Typography>

        {!haveFeedback && (
          <>
            <Form
              onSubmit={sendFeedback}
              initialValues={{ voucher: false, message: "" }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={3} direction="column" alignItems="stretch">
                    {[
                      { label: "It was helpful to have information about my treatment in video form.", name: "helpful" },
                      { label: "I feel that I understand my treatment better?", name: "understand" },
                      { label: "I feel more in control of my treatment.", name: "control" },
                      { label: "I feel more trust towards my dentist.", name: "trust" },
                      { label: "I feel more relaxed going into my next appointment.", name: "relaxed" },
                      { label: "I am glad that my dentist sent me this educational material", name: "educational" },
                    ].map(({ label, name }) => (
                      <Grid item key={name} xs={12}>
                        <Radios
                          label={label}
                          name={name}
                          required={true}
                          className={styles.radio}
                          data={[
                            { label: "😞", value: "1" },
                            { label: "🙁", value: "2" },
                            { label: "😐", value: "3" },
                            { label: "🙂", value: "4" },
                            { label: "😃", value: "5" },
                          ]}
                        />
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <TextField
                        label="What could be improved?"
                        name="message"
                        type="text"
                        multiline
                        variant="outlined"
                        fullWidth
                        rows={3}
                        className={styles.textField}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Checkboxes
                        name="voucher"
                        data={{
                          label: "I am happy for Medicube health to contact me to give more feedback for a chance to win a £150 Amazon voucher:",
                          value: true,
                        }}
                      />
                    </Grid>

                    {values.voucher && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            label="Name"
                            name="name"
                            type="text"
                            required
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Phone"
                            name="phone"
                            type="text"
                            required
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Email"
                            name="email"
                            required
                            type="email"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}

                    {feedback.isError && (
                      <Typography variant="body1" color="error" align="center" paragraph>
                        Something went wrong. Please fill all required fields and try again.
                      </Typography>
                    )}

                    <Grid item xs={12}>
                      <Button
                        className={styles.submitButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={feedback.isLoading}
                      >
                        Submit Feedback
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </>
        )}
      </Box>
    </div>
  );
}
